<template>
  <div class="productPage">
    <!-- Product Form -->
    <form @submit.prevent="saveChanges">
      <va-card stripe="secondary">

        <!-- Controls -->
        <div class="row">
          <div class="flex xs6 md10">
            <h1 class="display--1 text--capitalize">{{ mode }} Product Form</h1>
          </div>
          <div class="flex xs6 md2">
            <va-button
              outline
              medium
              color="info"
              type="button"
              icon="fa fa-arrow-left"
              v-on:click="goBack">
              Back
            </va-button>
          </div>
        </div>

        <!-- Row 1 -->
        <div class="row">
          <!-- Product title -->
          <div class="flex md8 xs12">
            <va-input
              label="Title"
              v-model="product.title"
              placeholder="Type title here">
              <va-icon
                slot="prepend"
                color="success"
                name="fa fa-header"
              />
            </va-input>
          </div>

          <!-- Product id -->
          <div class="flex md4 xs12">
            <va-input
              label="Product ID"
              v-bind:disabled="mode === 'edit'"
              v-model="product.productID"
              placeholder="Type Product ID here"
            >
              <va-icon
                slot="prepend"
                v-bind:color="mode === 'edit' ? 'gray' : 'success'"
                name="fa fa-file-text"
              />
            </va-input>
          </div>
        </div>

        <!-- Row 2 -->
        <div class="row">
          <!-- Description -->
          <div class="flex md8 xs12">
            <h6 class="display--6">Description</h6>
            <RichTextEditor
              ref="richTextEditor"
              v-bind:description="product.description"></RichTextEditor>
          </div>

          <!-- Brand / Region -->
          <div class="flex md4 xs12">
            <va-input
              label="Brand / Region"
              v-model="product.brand"
              placeholder="Type Brand / Region here"
            >
              <va-icon
                slot="prepend"
                color="success"
                name="fa fa-globe"
              />
            </va-input>

            <!-- Category / Species -->
            <va-input
              label="Category / Species"
              v-model="product.category"
              placeholder="Type Category / Species here"
            >
              <va-icon
                slot="prepend"
                color="success"
                name="fa fa-cog"
              />
            </va-input>

            <!-- Sub Category / Species-->
            <va-input
              label="Sub Category / Species"
              v-model="product.subCategory"
              placeholder="Type Sub Category / Species here"
            >
              <va-icon
                slot="prepend"
                color="success"
                name="fa fa-cogs"
              />
            </va-input>
          </div>
        </div>
      </va-card>

      <va-card class="my-3" stripe="info">
        <!-- Row 3 -->
        <div class="row">
          <!-- Live Aninamls -->
          <va-checkbox
            class="flex md2 xs12 mb-3"
            :label="$t('Live Shipment')"
            v-model="product.liveShipping"
          />

          <!-- Live Shipping Price -->
          <va-input
            class="flex md4 xs12"
            label="Live Shipping Price"
            v-model="product.liveShippingPremium"
            placeholder="Type 9.50 Here"
            v-bind:disabled="!product.liveShipping"
          >
            <va-icon
              slot="prepend"
              name="fa fa-truck"
              v-bind:color="product.liveShipping ? 'success': 'gray'"
            />
          </va-input>

          <!-- Caresheet -->
          <va-input
            class="flex md6 xs12"
            label="Caresheet url end"
            v-model="product.careSheet"
            placeholder="Type Caresheet url here"
            v-bind:disabled="!product.liveShipping"
          >
            <va-icon
              slot="prepend"
              name="fa fa-file-o"
              v-bind:color="product.liveShipping ? 'success': 'gray'"
            />
          </va-input>
        </div>

        <!-- Row 4 -->
        <div class="row">

          <!-- Live Aninamls -->
          <va-checkbox
            class="flex md2 xs12 mb-3"
            :label="$t('Feature Product')"
            v-model="product.status"
          />

          <!-- Availability -->
          <div class="flex md3 xs12">
            <va-select
              label="Availability"
              v-model="product.availability"
              :options="availabilityOptions"
            />
          </div>

          <!-- Product Type -->
          <div class="flex md3 xs12">
            <va-select
              label="Product Type"
              v-model="product.productType"
              :options="productTypeOptions"
            />
          </div>

          <!-- Warrenty -->
          <va-input
            class="flex md4 xs12"
            label="Product Warrenty"
            v-model="product.warrenty"
            placeholder="Type Warrenty length Here"
          >
            <va-icon
              slot="prepend"
              color="success"
              name="fa fa-trophy"
            />
          </va-input>
        </div>
      </va-card>

      <va-card class="my-3" stripe="warning">
        <!-- Row 5 Images-->
        <h1>Product Images</h1>
        <div class="row">
          <!-- New Image -->
          <va-input
            class="flex xs12 md8"
            label="Add New Image"
            v-model="newImage"
            placeholder="Type Image url Here"
          >
            <va-icon
              slot="prepend"
              color="success"
              name="fa fa-camera"
            />
          </va-input>

          <!-- Add Image Button -->
          <div class="flex xs12 md4">
            <va-button
              medium
              color="info"
              type="button"
              icon="fa fa-plus"
              v-on:click="addImage">
              Add Product Image
            </va-button>
          </div>
        </div>

        <!-- Row 6 Images Ext. -->
        <div class="flex xs12">
          <va-list fit class="mb-2">

            <va-list-label>
              {{ $t('All Product Images') }}
            </va-list-label>

            <va-item
              v-for="image in product.images"
              v-bind:key="image">

              <!-- Image Preview -->
              <div class="row">
                <div class="flex xs12 md2">
                  <va-item-section side>
                    <img
                      class="img-fluid"
                      v-bind="productImage"
                      :src="product.images[product.images.indexOf(image)]"
                      :alt="product.imagesAltText[product.images.indexOf(image)]"
                    />
                  </va-item-section>
                </div>

                <!-- Image Alt Text -->
                <div class="flex xs12 md8">
                  <va-item-section>
                    <va-input
                      class="px-md3"
                      label="Image Alt Text"
                      v-model="product.imagesAltText[product.images.indexOf(image)]"
                      cl
                      placeholder="Type Image alt text Here">
                      <va-icon
                        slot="prepend"
                        color="gray"
                        name="fa fa-image"
                      />
                    </va-input>
                  </va-item-section>
                </div>

                <!-- Remove Image -->
                <div class="flex xs12 md2">
                  <va-item-section side>
                    <va-button
                      medium
                      color="info"
                      icon="fa fa-trash"
                      type="button"
                      v-on:click="removeImage(product.images.indexOf(image))"
                    />
                  </va-item-section>
                </div>
              </div>

            </va-item>
          </va-list>
        </div>
      </va-card>

      <va-card class="my-3" stripe="success">
        <!-- Row 7 Options -->
        <h1>Options</h1>
        <div class="row">
          <!-- New Option -->
          <va-input
            class="flex xs12 md8"
            label="Add New Option"
            v-model="newOption"
            placeholder="Type Option Here"
          >
            <va-icon
              slot="prepend"
              color="success"
              name="fa fa-sliders"
            />
          </va-input>

          <!-- Add Option Button -->
          <div class="flex xs12 md4">
            <va-button
              medium
              color="info"
              type="button"
              icon="fa fa-plus"
              v-on:click="addOption">
              Add Product Option
            </va-button>
          </div>
        </div>

        <!-- Row 8 Options Ext. -->
        <div class="flex xs12">
          <va-list fit class="mb-2">

            <va-list-label>
              {{ $t('Add Stock') }}
            </va-list-label>

            <va-item
              v-for="option in product.options"
              v-bind:key="'option ' + option">

              <!-- Option -->
              <div class="row">
                <div class="flex xs12 md4">
                  <va-item-section>
                    <va-input
                      class="px-md3"
                      label="Option"
                      v-model="product.options[product.options.indexOf(option)]"
                      placeholder="Type Option Here">
                      <va-icon
                        slot="prepend"
                        color="gray"
                        name="fa fa-sliders"
                      />
                    </va-input>
                  </va-item-section>
                </div>

                <!-- Quantity -->
                <div class="flex xs12 md3">
                  <va-item-section>
                    <div class="px-md3">
                      <va-select
                        label="Quantity"
                        v-model="product.quantity[option]"
                        :options="quantityOptions"
                      />
                    </div>
                  </va-item-section>
                </div>

                <!-- Price -->
                <div class="flex xs12 md3">
                  <va-item-section>
                    <va-input
                      class="px-md3"
                      label="Price"
                      v-model="product.price[option]"
                      placeholder="Type Price Here">
                      <va-icon
                        slot="prepend"
                        color="gray"
                        name="fa fa-image"
                      />
                    </va-input>
                  </va-item-section>
                </div>

                <!-- Remove Option -->
                <div class="flex xs12 md2">
                  <va-item-section side>
                    <va-button
                      medium
                      color="info"
                      icon="fa fa-trash"
                      type="button"
                      v-on:click="removeOption(product.options.indexOf(option))"
                    />
                  </va-item-section>
                </div>

                <!-- Devider -->
                <hr/>

              </div>
            </va-item>
          </va-list>
        </div>

        <!-- Controls Bottom -->
        <div class="row">

          <!-- Go Back -->
          <div class="flex xs12 md9">
            <va-button
              outline
              medium
              color="info"
              type="button"
              icon="fa fa-arrow-left"
              v-on:click="goBack">
              Back
            </va-button>
          </div>

          <!-- Save form -->
          <div class="flex xs12 md3">
            <va-button
              medium
              color="info"
              type="submit"
              icon="fa fa-save"
            >
              Save Product
            </va-button>
          </div>
        </div>

      </va-card>
    </form>
  </div>
</template>

<script>
import { productCollection } from '@/firebaseConfig.js'
import RichTextEditor from '@/components_tc/utilities/RichTextEditor.vue'
export default {
  name: 'productPage',
  props: ['product', 'mode'],
  components: {
    RichTextEditor,
  },
  data () {
    return {
      newImage: '',
      newOption: '',
      productImage: { width: 100, height: 100, class: 'm1' },
      availabilityOptions: ['In Stock', 'Out of Stock'],
      productTypeOptions: ['Animal', 'Aquarium Supplies', 'Pond Supplies', 'Reptile Supplies'],
      quantityOptions: [...Array(51).keys()].join(' ').split(' '),
    }
  },
  methods: {
    goBack: function () {
      this.$emit('goBack')
    },

    addImage: function () {
      this.product.images.push(this.newImage)
    },

    addOption: function () {
      this.product.options.push(this.newOption)
    },

    removeImage: function (index) {
      this.product.images.splice(index, 1)
    },

    removeOption: function (index) {
      this.product.options.splice(index, 1)
    },

    isFeaturedProduct: function () {
      if (this.product.status) {
        this.$emit('featureProduct', this.product)
      }
    },

    saveChanges: function () {
      const _this = this
      this.castValues(false)
      var richTextEditor = this.$refs.richTextEditor.editor
      this.product.description = richTextEditor.getHTML()
      productCollection
        .doc(this.product.productID)
        .set(this.product)
        .then(() => {
          _this.isFeaturedProduct()
          _this.$emit('saveProduct')
          _this.goBack()
        })
    },

    castValues: function (toString) {
      Object.entries(this.product.quantity).forEach(([option, value]) => {
        if (toString) {
          this.product.quantity[option] = value.toString()
        } else {
          this.product.quantity[option] = parseFloat(value)
        }
      })

      Object.entries(this.product.price).forEach(([option, value]) => {
        if (toString) {
          this.product.price[option] = value.toString()
        } else {
          this.product.price[option] = parseFloat(value)
        }
      })
    },
  },
  mounted () {
    try {
      this.castValues(true)
    } catch (e) {
      console.log('ERROR (No Product): ' + e)
    }
  },
}
</script>

<style>
textarea {
  width: 100%;
  border: 1px solid #9ba4b4;
  background-color: #f5f5f5;
  border-radius: 4px;
  box-sizing: border-box;
}
</style>

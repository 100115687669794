<template>
  <div class="editor">
    <div class="editorContainer my-4">
      <div class="editorHeader">
        <h3 class="ml-2 pt-1"> EDITOR MENU</h3>
        <editor-menu-bar :editor="editor" v-slot="{ commands, isActive }">
          <div class="menubar">

            <!-- Bold menu item -->
            <va-button
              type="button"
              class="menubar__button"
              :class="{ 'is-active': isActive.bold() }"
              v-on:click="commands.bold"
              icon="fa fa-bold"
              color="success">
            </va-button>

            <!-- Italic menu item -->
            <va-button
              type="button"
              class="menubar__button"
              :class="{ 'is-active': isActive.italic() }"
              v-on:click="commands.italic"
              icon="fa fa-italic"
              color="success">
            </va-button>

            <!-- Strike menu item -->
            <va-button
              type="button"
              class="menubar__button"
              :class="{ 'is-active': isActive.strike() }"
              v-on:click="commands.strike"
              icon="fa fa-strikethrough"
              color="success">
            </va-button>

            <!-- Underline menu item -->
            <va-button
              type="button"
              class="menubar__button"
              :class="{ 'is-active': isActive.underline() }"
              v-on:click="commands.underline"
              icon="fa fa-underline"
              color="success">
            </va-button>

            <!-- Heading menu item -->
            <va-button
              type="button"
              class="menubar__button"
              :class="{ 'is-active': isActive.heading({level: 1}) }"
              v-on:click="commands.heading({ level: 1})"
              color="success">
              H1
            </va-button>

            <!-- Bullet List menu item -->
            <va-button
              type="button"
              class="menubar__button"
              :class="{ 'is-active': isActive.bullet_list() }"
              v-on:click="commands.bullet_list"
              icon="fa fa-list"
              color="success">
            </va-button>

            <!-- Horizonal_rule menu item -->
            <va-button
              type="button"
              class="menubar__button"
              v-on:click="commands.horizontal_rule"
              icon="fa fa-ellipsis-h"
              color="success">
            </va-button>

            <!-- Undo menu item -->
            <va-button
              type="button"
              class="menubar__button"
              v-on:click="commands.undo"
              icon="fa fa-undo"
              color="success">
            </va-button>

          </div>
        </editor-menu-bar>
      </div>

      <!-- Edit Content -->
      <div v-if="editor" class="editorBox">
        <editor-content :editor="editor" class="editor__content"/>
      </div>
    </div>

    <!-- Editor Output preview -->
    <div v-if="editor" class="previewBox">
      <h3> OUTPUT PREVIEW</h3>
      <hr>
      <div v-html="formatHtml(editor.getHTML())"/>
    </div>

  </div>
</template>

<script>
import { Editor, EditorContent, EditorMenuBar } from 'tiptap'
import {
  HardBreak,
  Heading,
  HorizontalRule,
  OrderedList,
  BulletList,
  ListItem,
  TodoItem,
  TodoList,
  Bold,
  Italic,
  Link,
  Strike,
  Underline,
  History,
} from 'tiptap-extensions'

export default {
  name: 'RichTextEditor',
  props: ['description'],
  components: {
    EditorContent,
    EditorMenuBar,
  },
  computed: {
    RichTextContent: function () {
      return this.$store.state.richTextContent
    },
  },
  data () {
    return {
      editor: null,
    }
  },
  methods: {
    formatHtml: function (html) {
      let preview = html
      preview = preview.replaceAll('<li><p>', '<li><p>●')
      preview = preview.replaceAll('<p></p>', '<p><br></p>')
      return preview
    },

  },
  mounted () {
    this.editor = new Editor({
      content: this.description,
      extensions: [
        new BulletList(),
        new HardBreak(),
        new Heading({ levels: [1] }),
        new HorizontalRule(),
        new ListItem(),
        new OrderedList(),
        new TodoItem(),
        new TodoList(),
        new Link(),
        new Bold(),
        new Italic(),
        new Strike(),
        new Underline(),
        new History(),
      ],
    })
  },
  beforeDestroy () {
    this.editor.destroy()
  },
}
</script>

<style>
.editorContainer {
  border: 2px solid black;
  border-radius: 5px;
}

.editorHeader {
  color: white;
  border-radius: 5px 5px none none;
  background-color: rgba(6, 27, 58);
}

.editorBox {
  color: white;
  padding: 20px;
  background-color: rgba(20, 46, 84);
}

.previewBox {
  color: black;
  padding: 20px;
  background-color: #f4f4f4;
  border: 2px solid #f4f4f4;
  border-radius: 5px;
}
</style>
